import {
    Form,
    useActionData,
    useNavigation,
    useSubmit,
} from '@remix-run/react';
import { useForm } from 'react-hook-form';
import { Button } from '~/cds/Button';
import { Input } from '~/cds/Input';
import { Label } from '~/cds/Label';
import { ErrorSpan } from '~/common/components';
import { getResolverFromSchema } from '~/common/utils/form';
import { LoginSchema } from '~/validations/auth';

interface LoginFormProps {
    redirectTo?: string | null;
}

export const LoginForm = ({ redirectTo }: LoginFormProps) => {
    const submit = useSubmit();
    const navigation = useNavigation();
    const isFormSubmitting = navigation.state === 'submitting';
    const actionData = useActionData();
    const resolver = getResolverFromSchema(LoginSchema);
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        resolver,
        defaultValues: {
            redirectTo: redirectTo || '',
            email: '',
            password: '',
        },
    });

    return (
        <Form
            className="space-y-8"
            onSubmit={handleSubmit(async (value, _event) =>
                submit(value, { method: 'POST' })
            )}
        >
            <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                    {...register('email')}
                    id="email"
                    placeholder="you@creatordao.com"
                    className="w-full"
                />
                <ErrorSpan
                    formError={{
                        name: 'email',
                        errors,
                        serverErrors: actionData?.errors,
                    }}
                />
            </div>
            <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                    {...register('password')}
                    id="password"
                    placeholder="********"
                    type="password"
                    className="w-full"
                />
                <ErrorSpan
                    formError={{
                        name: 'password',
                        errors,
                        serverErrors: actionData?.errors,
                    }}
                />
            </div>
            <input
                hidden
                type="hidden"
                name="redirectTo"
                value={redirectTo || ''}
            />
            <Button
                variant="primary"
                size="lg"
                type="submit"
                disabled={isFormSubmitting}
                loading={isFormSubmitting}
                className="xs:min-w-full md:w-[120px] md:min-w-[120px]"
            >
                Log In
            </Button>
        </Form>
    );
};
