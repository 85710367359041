import type {
    ActionArgs,
    DataFunctionArgs,
    MetaFunction,
} from '@remix-run/node';
import { Link, useSearchParams } from '@remix-run/react';
import { redirect, typedjson, useTypedLoaderData } from 'remix-typedjson';
import { Typography } from '~/cds/Typography';
import {
    ERROR_AUTH_FIREBASE,
    ERROR_AUTH_WRONG_PASSWORD,
    ERROR_INCORRECT_CREDENTIALS,
    ERROR_LOGIN_ISSUE,
    ERROR_NO_ACCOUNT,
    ERROR_NO_AVALON_ACCESS,
    ERROR_NO_AVALON_ACCESS_USER,
    ERROR_UNEXPECTED,
    ERROR_USER_NOT_FOUND,
} from '~/common/constants/auth';
import { EVENTS } from '~/common/constants/events';
import { URLS } from '~/common/constants/urls';
import { AuthPageLayout } from '~/common/layouts/AuthPageLayout';
import type { ZodFormActionData } from '~/common/utils/form';
import { getErrorsFromZodIssues } from '~/common/utils/form';
import { getFlags, trackEvent } from '~/common/utils/posthog';
import { AuthProvider } from '~/components/auth/AuthProvider/AuthProvider';
import { LoginForm } from '~/components/auth/LoginForm';
import {
    createUserSession,
    getUserId,
    login,
    verifySocialAuth,
} from '~/session.server';
import { LoginSchema } from '~/validations/auth';

export const meta: MetaFunction = () => ({
    description: 'Login to CreatorDAO!',
    title: 'CreatorDAO | Login',
});

export const loader = async ({ request }: DataFunctionArgs) => {
    const userId = await getUserId(request);
    if (userId) return redirect(URLS.HOME);
    const flags = await getFlags();
    return typedjson({ flags });
};

export const action = async ({ request }: ActionArgs) => {
    const formValues = Object.fromEntries(await request.formData());

    if (formValues.socialAuthUserId && formValues.email) {
        const socialAuthResult = await verifySocialAuth({
            id: formValues.socialAuthUserId as string,
            email: formValues.email as string,
            display_name: formValues.display_name as string,
            photo_url: formValues.photo_url as string,
        });

        if ('error' in socialAuthResult) {
            return typedjson({ socialAuthError: socialAuthResult.error });
        }

        return createUserSession(socialAuthResult.id, URLS.HOME);
    }

    const result = LoginSchema.safeParse(formValues);

    if (result.success) {
        const { email, password } = result.data;
        const loginResult = await login({
            email,
            password,
        });

        if ('error' in loginResult) {
            let errorMsg;
            switch (loginResult.error) {
                case ERROR_AUTH_FIREBASE:
                    errorMsg = ERROR_LOGIN_ISSUE;
                    break;
                case ERROR_AUTH_WRONG_PASSWORD:
                    errorMsg = ERROR_AUTH_WRONG_PASSWORD;
                    break;
                case ERROR_NO_ACCOUNT:
                case ERROR_USER_NOT_FOUND:
                    errorMsg = ERROR_INCORRECT_CREDENTIALS;
                    break;
                case ERROR_NO_AVALON_ACCESS:
                    errorMsg = ERROR_NO_AVALON_ACCESS_USER;
                    break;
                default:
                    errorMsg = ERROR_UNEXPECTED;
                    break;
            }

            return typedjson<ZodFormActionData>({
                errors: {
                    password: errorMsg,
                },
            });
        }

        trackEvent({ event: EVENTS.AUTH.SIGN_IN, properties: { email } });
        return createUserSession(
            loginResult.id,
            result.data.redirectTo || URLS.HOME
        );
    }

    return typedjson<ZodFormActionData>({
        errors: getErrorsFromZodIssues(result.error.issues),
    });
};

export default function Login() {
    const [searchParams] = useSearchParams();
    const redirectTo = searchParams.get('redirectTo');
    const { flags } = useTypedLoaderData<typeof loader>();
    const socialLogins = flags['social_login'] as boolean;

    return (
        <AuthPageLayout>
            <Typography
                intent="heading"
                size="xl"
                className="xs:hidden md:block"
            >
                Log in to CreatorDAO
            </Typography>
            <Typography
                intent="heading"
                size="lg"
                className="xs:block md:hidden"
            >
                Log in to CreatorDAO
            </Typography>
            <LoginForm redirectTo={redirectTo} />
            <Link to={URLS.PUBLIC.AUTH.FORGOT_PASSWORD}>
                <Typography
                    intent="body"
                    size="md"
                    className="max-w-fit underline"
                >
                    Forgot your password?
                </Typography>
            </Link>
            <AuthProvider socialLogins={socialLogins} />
            <span className="flex gap-x-1 text-[16px] leading-[24px] tracking-[-0.32px] text-gray-500 xs:justify-center md:justify-start">
                New to CreatorDAO?
                <Link
                    to={URLS.PUBLIC.AUTH.SIGNUP}
                    className="text-black underline"
                >
                    Create a new account
                </Link>
            </span>
        </AuthPageLayout>
    );
}
